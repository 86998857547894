import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { Vessel } from "./models/vessels.model";

@Injectable({ providedIn: "root" })
export class VesselsService {

  private apiURL: string;
  
  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiURL = `${this.env.getValueTransferState(API_URL)}/app/vessel`;
  }

  getVessels(skipCount: number, maxResultsCount: number, filter: string) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        SkipCount: skipCount,
        [maxResultsCount === 0 ? "GetAll" : "MaxResultCount"]: maxResultsCount === 0 ? true : maxResultsCount,
        SearchQ: filter
      }
    });
    return this.http.get<any>(this.apiURL, { params });
  }

  update(vessel: Vessel) {
    return this.http.put<any>(`${this.apiURL}/${vessel.id}`, vessel);
  }

  create(vessel: Vessel) {
    return this.http.post<any>(this.apiURL, vessel);
  }

  remove(vesselId: number) {
    return this.http.delete(`${this.apiURL}/${vesselId}`);
  }

  checkImport(form: FormData) {
    return this.http.post<any>(`${this.apiURL}/checkFile`, form);
  }

  import(form: FormData) {
    return this.http.post<any>(`${this.apiURL}/uploadFile`, form);
  }
}

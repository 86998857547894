import { Vessel } from "../models/vessels.model";

export class LoadVessels {
  public static readonly type = "[Vessels] load vessels";
  constructor(
    public skipCount: number,
    public maxResultsCount: number,
    public searchQ?: string
  ) {}
}

export class AddVessel {
  public static readonly type = "[Vessels] add vessel";
  constructor(
    public payload: Vessel,
    public callbackSuccess: () => void,
    public callbackError: () => void,
  ) {}
}

export class UpdateVessel {
  public static readonly type = "[Vessels] update vessel";
  constructor(
    public payload: Vessel,
    public callbackSuccess: () => void,
    public callbackError: () => void,
  ) {}
}

export class RemoveVessel {
  public static readonly type = "[Vessels] remove vessel";
  constructor(
    public vesselId: number,
    public callbackSuccess: () => void,
    public callbackError: () => void,
  ) {}
}

export class ImportVessels {
  public static readonly type = "[Vessels] import vessels";
  constructor(
    public payload: FormData,
    public dialogCallback: (error?) => void
  ) {}
}
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults } from "../../admin.state";
import { VesselsService } from "../vessels.service";
import { AddVessel, ImportVessels, LoadVessels, RemoveVessel, UpdateVessel } from "./vessels.actions";
import { VesselsStateModel } from "./vessels.model";

@State<VesselsStateModel>({
  name: "vessels",
  defaults: AdminPagesStateDefaults
})
@Injectable()
export class VesselsState {
  constructor(private vesselsService: VesselsService) {}

  @Selector() static vessels(state: VesselsStateModel) { return state.items; }
  @Selector() static vesselsCount(state: VesselsStateModel) { return state.totalCount; }
  @Selector() static loading(state: VesselsStateModel) { return state.loading; }
  @Selector() static saving(state: VesselsStateModel) { return state.saving; }

  @Action(LoadVessels)
  loadVessels(ctx: StateContext<VesselsStateModel>, action: LoadVessels) {
    ctx.patchState({
      items: [],
      totalCount: 0,
      loading: true
    });

    return this.vesselsService.getVessels(action.skipCount, action.maxResultsCount, action.searchQ || '').pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            totalCount: response.totalCount,
            loaded: true,
            loading: false
          });
        }
      )
    );
  }

  @Action(AddVessel)
  addVessel(ctx: StateContext<VesselsStateModel>, action: AddVessel) {
    ctx.patchState({ saving: true });

    return this.vesselsService.create(action.payload).toPromise().then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: [response, ...state.items],
          totalCount: state.totalCount + 1,
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(UpdateVessel)
  updateVessel(ctx: StateContext<VesselsStateModel>, action: UpdateVessel) {
    ctx.patchState({ saving: true });

    return this.vesselsService.update(action.payload).toPromise().then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: getUpdatedItems(response, state.items),
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(RemoveVessel)
  removeVessel(ctx: StateContext<VesselsStateModel>, action: RemoveVessel) {
    return this.vesselsService.remove(action.vesselId).toPromise().then(
      () => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: state.items.filter((item) => {
            return item.id !== action.vesselId;
          })
        });
      },
      (error) => {
        action.callbackError();
      }
    );
  }

  @Action(ImportVessels)
  import(ctx: StateContext<VesselsStateModel>, action: ImportVessels) {
    return this.vesselsService.import(action.payload).toPromise().then(
      () => {
        action.dialogCallback();
      },
      (error) => {
        action.dialogCallback(error);
      }
    );
  }
}
